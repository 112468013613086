var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('List',{attrs:{"available-actions":{
    createAction: false,
    showAction: false,
  }},scopedSlots:_vm._u([{key:"filters",fn:function(query){return [_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('MaskField',{attrs:{"value":query.inspectionId === null ? '' : query.inspectionId,"mask":_vm.maskPosNumber,"label":"ID осмотра","hide-details":"auto","clearable":query.inspectionId !== null,"outlined":"","dense":"","disabled":_vm.listIsLoading},on:{"input":function($event){query.inspectionId = $event || null}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('DateRangePicker',{attrs:{"value":_vm.dates,"label":"Диапазон отправки осмотров в НИИАТ","show-quick-options":"","with-time":"","outlined":"","dense":"","clearable":"","placeholder":_vm.datesPlaceholder,"disabled":_vm.listIsLoading},on:{"input":_vm.setDateFilters}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Версия ПО ПАК","items":_vm.softwares,"item-value":"version","item-text":"version","hide-details":"","outlined":"","dense":"","disabled":_vm.listIsLoading,"menu-props":{ offsetY: true }},model:{value:(query.softwareVersion),callback:function ($$v) {_vm.$set(query, "softwareVersion", $$v)},expression:"query.softwareVersion"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"Статус","items":_vm.statusList,"item-value":"value","item-text":"name","hide-details":"","outlined":"","dense":"","disabled":_vm.listIsLoading,"menu-props":{ offsetY: true }},model:{value:(query.status),callback:function ($$v) {_vm.$set(query, "status", $$v)},expression:"query.status"}})],1)]}},{key:"table.item.inspection",fn:function(ref){
  var item = ref.item;
return [_c('TittledText',{attrs:{"subtitle":item.type},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_vm._v("#"+_vm._s(item.inspectionId))])]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.dateTime(item.timestamps.processedAt)))]),_c('TerminalDateTime',{attrs:{"date":item.timestamps.processedAt,"offset":item.timezoneOffsetMin}}),_c('div',[_vm._v(_vm._s(item.organization.name))]),_c('div',[_vm._v("ПАК "+_vm._s(_vm.hostInfo(item)))])],1)]}},{key:"table.item.resolutionSuccess",fn:function(ref){
  var value = ref.value;
return [_c('Indicator',{attrs:{"active":value,"tooltip":value ? 'Допущен/Прошел' : 'Не допущен/Не прошел'}})]}},{key:"table.item.status",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"10px"}},[_c('Round',{staticStyle:{"min-width":"1rem"},attrs:{"type":_vm.statusClass(item.status)}}),_c('div',[_vm._v(_vm._s(item.result))])],1)]}},{key:"item.actions.prepend",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(_vm.$can('NII_PIN') && item.isReviewable)?_c('CardActionBtn',{attrs:{"label":"Открыть JSON","disabled":_vm.$wait('loadingJson')},on:{"click":function($event){return _vm.openJson(item.inspectionId)}}}):_vm._e(),(_vm.$can('NII_RIN') && item.status === false)?_c('CardActionBtn',{attrs:{"label":"Переотправить JSON","disabled":_vm.$wait('resetJson')},on:{"click":function($event){return _vm.resetJson(item.inspectionId)}}}):_vm._e()],1)]}},{key:"item.actions.append",fn:function(ref){
  var item = ref.item;
return [(_vm.$can('I_RD'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
            name: 'inspections:card',
            params: { id: item.inspectionId },
          }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Карточка осмотра")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }