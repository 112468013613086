import { MaskedRange } from 'imask';

/*
 * DatePicker
 */
export const maskDatePicker = {
  mask: Date,
  pattern: 'dd.MM.y',
  lazy: false,

  min: new Date(1900, 0, 1),
  max: new Date(2029, 11, 31),

  blocks: {
    dd: { mask: MaskedRange, from: 1, to: 31 },
    MM: { mask: MaskedRange, from: 1, to: 12 },
    y: { mask: MaskedRange, from: 1900, to: 2030 },
  },
};

export const maskTime = {
  overwrite: true,
  autofix: true,
  mask: 'HH:MM',
  blocks: {
    HH: { mask: MaskedRange, placeholderChar: 'HH', from: 0, to: 23 },
    MM: { mask: MaskedRange, placeholderChar: 'MM', from: 0, to: 59 },
  },
};

export const maskScheduleTime = {
  mask: 'HH:M0',
  overwrite: true,
  lazy: true,
  blocks: {
    HH: { mask: MaskedRange, placeholderChar: 'H', from: 0, to: 23 },
    M: { mask: MaskedRange, placeholderChar: 'M', from: 0, to: 5 },
    0: { mask: MaskedRange, placeholderChar: '0', from: 0, to: 0 },
  },
};

/*
 * CryptoPro
 */
export const maskInputCryptoPro = {
  mask: 'XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XX',
  definitions: { X: /[0-9A-Fa-f]/ },
  prepare: str => str.toUpperCase(),
};

export function maskTextCryptoPro(val) {
  return val.replace(/[\d\w]{4}/g, '$& ');
}

/*
 * SignMe
 */
export const maskInputSignMe = {
  mask: /^[0-9a-fA-F]{1,10}$/,
};

/*
 * Phone
 */
export const maskInputPhone = {
  mask: '+{7} (000) 000-00-00',
  prepare: (appended, masked) =>
    appended === '8' && masked.value === '' ? '' : appended,
};

export function maskTextPhone(val) {
  if (typeof val !== 'string') return val;

  const digitals = val.replace(/\D/g, '');
  // В номере телефона российского формата содержится
  // - 10 цифр (если без кода страны)
  // - 11 цифр (с учетом "7" / "8")
  if (digitals.length < 10 || digitals.length > 11) return val;

  const phone = digitals.replace(/^(\+7|7|8)/, '');
  const g = phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
  return `+7 (${g[1]}) ${g[2]}-${g[3]}-${g[4]}`;
}

export function unmaskPhone(maskedValue) {
  return maskedValue ? maskedValue.replace(/[()\s-]/g, '') : maskedValue;
}

// ИНН
export const maskInputInn10 = {
  mask: 'XXXXXXXXXX',
  definitions: { X: /[0-9]/ },
};
export const maskInputInn12 = {
  mask: 'XXXXXXXXXXXX',
  definitions: { X: /[0-9]/ },
};

// КПП
export const maskInputKpp = {
  mask: 'XXXXXXXXX',
  definitions: { X: /[0-9]/ },
};

// ОГРН / ОГРНИП
export const maskInputOrgn = {
  mask: 'XXXXXXXXXXXXX', // 13
  definitions: { X: /[0-9]/ },
};
export const maskInputOrgnip = {
  mask: 'XXXXXXXXXXXXXXX', // 15
  definitions: { X: /[0-9]/ },
};

/*
 * СНИЛС
 */
export const maskInputSnils = {
  mask: 'XXX-XXX-XXX XX',
  definitions: { X: /[0-9]/ },
};

export function maskTextSnils(val) {
  if (typeof val !== 'string') return val;

  const digitals = val.replace(/\D/g, '');
  // СНИЛС содержит 11 символов (9 - номер, 2 - контрольная сумма)
  if (digitals.length !== 11) return val;

  const g = digitals.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
  return `${g[1]}-${g[2]}-${g[3]} ${g[4]}`;
}

export function unmaskSnils(maskedValue) {
  // Удаляем пробелы и минусы
  return maskedValue ? maskedValue.replace(/[\s-]/g, '') : maskedValue;
}

// Positive number
export const maskPosNumber = {
  mask: /^\d{1,90}$/,
};

// Positive decimal number
export const maskFloatNumber = {
  mask: /^[\d.]{1,90}$/,
};

/*
 * Host License
 */
export const maskInputHostLicense = {
  // Нули необходимо заэкранировать, иначе каретка будет вставать на их место
  // https://imask.js.org/guide.html#masked-pattern
  mask: '{A27\\092\\01819\\0\\0-}NUM',
  lazy: false,
  blocks: {
    NUM: { mask: 'XXXXX', definitions: { X: /[0-9]/ } },
  },
  prepare: str => str.toUpperCase(),
};

// Latitude
export const latitude = {
  mask: [{ mask: '00.`00000000000000' }, { mask: '0.`000000000000000' }],
};
export const longitude = {
  mask: [
    { mask: '000.`00000000000000' },
    { mask: '00.`000000000000000' },
    { mask: '0.`0000000000000000' },
  ],
};
