import {
  getSystemInfo,
  isValidSystemSetup,
  getUserCertificates,
} from '@libs/crypto-pro';
import signApi from '@/api/services/sign';
import { ACTIVE_SIGNATURE_TOKEN_KEY, TOKEN_TYPES } from '@/utils/constants';
import { CryptoProToken } from '@/models/tokens/CryptoProToken';
import { SignMeToken } from '@/models/tokens/SignMeToken';
import { WebStorage } from '@/plugins/webStorage';

/** Переопределение текста ошибок для доходчивости пользователей */
const USER_FRIENDLY_ERRORS = {
  'Нет доступных сертификатов': {
    message:
      'Сертификаты КриптоПро не обнаружены. Убедитесь, что сертификат подключен' +
      ' к компьютеру или установлен в систему',
    type: 'warning',
  },
};

/** Список ошибок, от которых цикл обнаружения токенов не должен падать */
const NOT_CRITICAL_MESSAGES = ['Нет доступных сертификатов'];

const TIMEOUT_REFRESH_CONNECTED_TOKENS = 10000;
const TIMEOUT_CHECK_ACTIVE_TOKEN = 15000;

/** Запросить у КриптоПро CSP список подключенных к системе сертификатов */
const getCryptoProTokens = async () => {
  // Делаем запрос подключенных сертификатов со сбросом кэша
  const connected = await getUserCertificates(true);
  const cryptoProTokens = [];

  for (const cert of connected) {
    const token = new CryptoProToken(cert);
    await token.computedFields();
    cryptoProTokens.push(token);
  }

  return cryptoProTokens;
};

const state = {
  signServiceError: null,

  timerCryptoProTokens: null,
  timerCheckToken: null,

  accountCertificates: null,

  cryptoProInfo: null,
  cryptoProInfoError: null,
  cryptoProTokens: null,
  cryptoProTokensError: null,
  cryptoProTokensErrorType: null,

  activeToken: null,
  activeTokenImprint: null,
  activeTokenError: null,
};

const getters = {
  signServiceError: state => state.signServiceError,

  timerCryptoProTokens: state => state.timerCryptoProTokens,
  timerCheckToken: state => state.timerCheckToken,

  accountCertificates: state => state.accountCertificates || [],

  cryptoProInfo: state => state.cryptoProInfo,
  cryptoProInfoError: state => state.cryptoProInfoError,
  cryptoProTokensInitialized: state => state.cryptoProTokens !== null,
  cryptoProTokens: state => state.cryptoProTokens,
  cryptoProTokensError: state => state.cryptoProTokensError,
  cryptoProTokensErrorType: state => state.cryptoProTokensErrorType,

  activeToken: state => state.activeToken,
  isActiveToken: state => !!state.activeToken,
  activeTokenImprint: state => state.activeTokenImprint,
  activeTokenError: state => state.activeTokenError,
};

const mutations = {
  timerCryptoProTokens: (state, id) => (state.timerCryptoProTokens = id),
  timerCheckToken: (state, id) => (state.timerCheckToken = id),

  setAccountCertificates: (state, list) => (state.accountCertificates = list),

  setCryptoProInfo: (state, data) =>
    (state.cryptoProInfo = Object.freeze(data)),
  setCryptoProInfoError: (state, error) => {
    const errMessage = error?.message || error;
    if (errMessage !== state.cryptoProTokensError) console.error(error);
    state.cryptoProInfoError = error;
  },
  setCryptoProTokens: (state, list) => {
    state.cryptoProTokens = list;
    state.cryptoProTokensError = null;
    state.cryptoProTokensErrorType = null;
  },
  setCryptoProTokensError: (state, err) => {
    const errMessage = err?.message || err;
    const fromDict = USER_FRIENDLY_ERRORS[errMessage];

    // Выводить сообщение в консоль, только если сообщение изменилось
    if ((fromDict?.message || errMessage) !== state.cryptoProTokensError)
      console.error(err);

    state.cryptoProTokensError = fromDict?.message || errMessage;
    state.cryptoProTokensErrorType = fromDict?.type;
    state.cryptoProTokens = [];
  },

  setActiveTokenImprint: (state, imprint) => {
    state.activeTokenImprint = imprint;
  },
  setActiveToken: (state, { token = null } = {}) => {
    if (token === null) {
      state.activeToken = null;
      WebStorage.removeItem(ACTIVE_SIGNATURE_TOKEN_KEY);
      return;
    }
    state.activeToken = token;
    WebStorage.setItem(
      ACTIVE_SIGNATURE_TOKEN_KEY,
      JSON.stringify({ type: token.data.type, serial: token.data.serial }),
    );
  },
  setActiveTokenError: (state, error) => {
    state.activeTokenError = error;
    console.error(error);
  },
};

const actions = {
  async init({ commit }) {
    try {
      const imprint = WebStorage.getItem(ACTIVE_SIGNATURE_TOKEN_KEY);
      if (imprint) commit('setActiveTokenImprint', JSON.parse(imprint));
    } catch (e) {
      WebStorage.removeItem(ACTIVE_SIGNATURE_TOKEN_KEY);
      console.error(e);
    }
  },

  /**
   * Метод инициализации данных о КриптоПро и проверка его установки
   */
  async initCryptoPro({ commit }) {
    // NOTE: skip window install cryptopro. See more:
    // https://github.com/vgoma/crypto-pro/blob/c0566b765455f1dea0c3074c806d1fcc4e1af2f6/src/vendor/cadesplugin_api.js
    window.cadesplugin_skip_extension_install = true;
    try {
      await isValidSystemSetup();
      commit('setCryptoProInfo', await getSystemInfo());
    } catch (e) {
      commit(
        'setCryptoProInfoError',
        'Плагин КриптоПро не настроен в браузере',
      );
    }
  },

  async clearActiveToken({ commit }) {
    commit('setActiveToken');
  },

  /**
   * Цикл получения списка подключенных сертификатов
   */
  async cycleRefreshCryptoProTokens({ getters, dispatch, commit }) {
    const { timerCryptoProTokens } = getters;
    if (timerCryptoProTokens) clearTimeout(timerCryptoProTokens);

    try {
      const cryptoProTokensList = await getCryptoProTokens();
      commit('setCryptoProTokens', cryptoProTokensList);
    } catch (err) {
      commit('setCryptoProTokensError', err);

      // Приостановить работу цикла при критической ошибке
      if (!NOT_CRITICAL_MESSAGES.includes(err?.message)) return;
    }

    commit(
      'timerCryptoProTokens',
      setTimeout(
        () => dispatch('cycleRefreshCryptoProTokens'),
        TIMEOUT_REFRESH_CONNECTED_TOKENS,
      ),
    );
  },

  /**
   * Цикл проверки наличия (и валидности) токена в системе
   */
  async cycleCheckActiveToken({ getters, dispatch, commit }) {
    const { timerCheckToken, activeToken } = getters;
    if (timerCheckToken) clearTimeout(timerCheckToken);
    if (!activeToken) {
      commit('setActiveTokenError', 'Активный сертификат не обнаружен');
      return;
    }

    try {
      let checker = null;
      if (activeToken.type === TOKEN_TYPES['CRYPTOPRO']) {
        checker = await activeToken.checkIsValid({
          cryptoProTokens: getters.cryptoProTokens,
        });
      }

      if (checker?.type === 'error') {
        commit('setActiveTokenError', checker?.message);
        await dispatch('clearActiveToken');
        return;
      }

      commit(
        'timerCheckToken',
        setTimeout(
          () => dispatch('cycleCheckActiveToken'),
          TIMEOUT_CHECK_ACTIVE_TOKEN,
        ),
      );
    } catch (e) {
      commit('setActiveTokenError', e);
      await dispatch('clearActiveToken');
    }
  },

  /**
   * Задать активный сертификат
   */
  async changeActiveToken({ getters, dispatch, commit }, { type, token }) {
    const accountCert = getters.accountCertificates.find(
      el => el.type === type && el.details.serial === token.serial,
    );

    if (!accountCert) {
      await dispatch('clearActiveToken');
      throw Error('Сертификат не подключен к активному аккаунту');
    }

    token.setAccountCertData(accountCert);

    if (!token.isInitialized) {
      await token.initialize();
    }

    await commit('setActiveToken', { token });

    if (type === TOKEN_TYPES['CRYPTOPRO']) {
      // REVIEW: think about whether need an additional check
      await dispatch('cycleRefreshCryptoProTokens');

      dispatch('cycleCheckActiveToken');
    }
  },

  /**
   * Выбрать активный сертификат по serial
   */
  async changeActiveTokenBySerial({ getters, dispatch }, { type, serial }) {
    if (type === TOKEN_TYPES['CRYPTOPRO']) {
      // If tokens have not been requested yet
      if (getters.cryptoProTokens === null) {
        if ((await dispatch('cycleRefreshCryptoProTokens')) === false) {
          throw Error('Ошибка запуска цикла получения токенов КриптоПро');
        }
      }
      const token = getters.cryptoProTokens.find(
        el => el.getSerial() === serial,
      );
      if (!token) throw Error('Токен CryptoPro не найден');

      await dispatch('changeActiveToken', { type, token });
    }

    if (type === TOKEN_TYPES['SIGNME']) {
      const accountCert = getters.accountCertificates.find(
        el => el.type === TOKEN_TYPES['SIGNME'] && el.details.serial === serial,
      );
      if (!accountCert) throw Error('Токен SignMe не найден');
      const token = new SignMeToken(accountCert);

      await dispatch('changeActiveToken', { type, token });
    }
  },

  /**
   * Запросить список сертификатов аккаунта
   */
  async fetchAccountCertificates({ commit }) {
    const list = await signApi.getMyCertificates();
    await commit('setAccountCertificates', list);
  },

  setTimerCryptoProTokens({ commit }, id) {
    commit('timerCryptoProTokens', id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
