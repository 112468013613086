<template>
  <List
    :available-actions="{
      createAction: false,
      showAction: false,
    }"
    @filters:resetHook="resetHook"
    @filters:searchHook="searchHook"
  >
    <!-- Фильтр-->
    <template #filters="query">
      <v-col cols="12" sm="4" md="3">
        <AppAccounts
          v-model="query.appAccountIds"
          title="Операторы ЭПЛ"
          :disabled="disableFilters"
          dense
          multiple
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <OrganizationSelect
          v-model="query.orgIds"
          :disabled="disableFilters"
          multiple
          dense
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <SearchEmployee
          ref="empSearch"
          v-model="query.employeeIds"
          store="INSPECTION_LIST"
          :org-ids="query.orgIds"
          :group-ids="query.employeeGroupIds"
          :org-groups-ids="query.orgGroupIds"
          :disabled="listIsLoading || disableFilters"
          :loading="listIsLoading"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="query.types"
          label="Тип титула"
          :items="ewbTypes"
          :disabled="disableFilters"
          item-value="value"
          item-text="name"
          hide-details
          clearable
          outlined
          dense
          multiple
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <MaskField
          :value="query.inspectionId || ''"
          :mask="maskPosNumber"
          label="ID осмотра"
          hide-details="auto"
          clearable
          outlined
          dense
          :disabled="listIsLoading"
          @input="inputInspectionId(query, $event)"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <VTextField
          v-model="query.documentFlowId"
          label="ID путевого листа (УИД_ПЛ)"
          hide-details="auto"
          :clearable="query.documentFlowId !== null"
          outlined
          dense
          :disabled="listIsLoading || disableFilters"
          @input="query.documentFlowId = $event || null"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <DateRangePicker
          :value="dates"
          label="Диапазон действия путевого листа"
          show-quick-options
          with-time
          outlined
          full-range
          dense
          clearable
          :placeholder="!disableFilters ? datesPlaceholder : null"
          :disabled="listIsLoading || disableFilters"
          @input="setDateFilters"
        />
      </v-col>
    </template>

    Кастомные колонки таблицы
    <template #table.item.failed="{ value }">
      <Round :type="!value ? 'green lighten-2' : 'red'" />
    </template>
    <template #table.item.employeeFIO="{ item }">
      <b> {{ item.employeeFIO }} </b> <br />
      {{ item.orgName }}
    </template>

    <template #item.actions.append="{ item }">
      <v-tooltip v-if="$can('I_RD') && item.inspectionId" left>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            :to="{
              name: 'inspections:card',
              params: { id: item.inspectionId },
            }"
            target="_blank"
            v-on="on"
          >
            <v-icon small>fa-eye</v-icon>
          </v-btn>
        </template>
        <span>Карточка осмотра</span>
      </v-tooltip>
    </template>
  </List>
</template>

<script>
import ListFactory from '@/components/crud/ListFactory';
import { entity, messages, filters } from '../entity';

import MaskField from '@/components/controls/MaskField';
import DateRangePicker from '@/components/controls/DateRangePicker.vue';
import OrganizationSelect from '@/components/controls/structures/OrganizationSelect';
import SearchEmployee from '@/modules/inspections/components/controls/SearchEmployee.vue';

import { maskPosNumber } from '@/utils/masks';
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import { EWB_TYPES } from '@/utils/constants';
import AppAccounts from '../components/AppAccounts';
import Round from '@/components/Round';

const List = ListFactory({
  storeModule: 'ADMIN/INTEGRATIONS/EPL',
  messages,
  entity,
  filters,
});

export default {
  components: {
    AppAccounts,
    List,
    OrganizationSelect,
    SearchEmployee,
    Round,
    MaskField,
    DateRangePicker,
  },
  mixins: [waitable],
  data() {
    return {
      disableFilters: false,
    };
  },

  computed: {
    ...mapGetters('ADMIN/INTEGRATIONS/EPL', [
      'listIsLoading',
      'shiftDateFrom',
      'shiftDateTo',
      'datePlaceholder',
    ]),
    maskPosNumber: () => maskPosNumber,
    ewbTypes: () => EWB_TYPES,
    dates() {
      return {
        dateStart: this.shiftDateFrom || null,
        dateEnd: this.shiftDateTo || null,
      };
    },
    datesPlaceholder() {
      if (!this.datePlaceholder) return null;
      return {
        dateStart: this.datePlaceholder.dateStart,
        dateEnd: this.datePlaceholder.dateEnd,
      };
    },
  },

  methods: {
    ...mapActions('ADMIN/INTEGRATIONS/EPL', ['setDateFilters']),
    searchHook(query) {
      if (query.inspectionId) {
        query.appAccountIds = null;
        query.orgIds = null;
        query.types = null;
        query.employeeIds = null;
        query.documentFlowId = null;
        query.shiftDateFrom = null;
        query.shiftDateTo = null;
        this.setDateFilters({ dateStart: null, dateEnd: null });
        this.disableFilters = true;
      }
    },
    resetHook() {
      this.$refs.empSearch.clear();
      this.disableFilters = false;
    },
    inputInspectionId(query, event) {
      query.inspectionId = event || undefined;
      if (!query.inspectionId) {
        this.disableFilters = false;
      }
    },
  },
};
</script>
